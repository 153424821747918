import { InvestmentActivityListResponse, InvestmentProject, InvestorDashboardType } from '../../../../types';
import { apiFetch } from '../api';
import { uploadFileToStorage } from '../storage';
import {
  CreateInvestmentActivityType,
  GetInvestorInvestmentActivityParams,
  SendInvoiceBodyType,
  SendReceiptBodyType,
  SendSignedSoftCommitmentBodyType,
  SendSoftCommitmentBodyType,
  UpdateInvestmentActivityType,
} from './types';

export const getDashboardData = async () => {
  try {
    const response: InvestorDashboardType = await apiFetch('investor/dashboard', { method: 'get' });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getInvestorProjectsData = async () => {
  try {
    const response: InvestmentProject[] = await apiFetch('investor/projects-list', { method: 'get' });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const createInvestmentActivity = async (body: CreateInvestmentActivityType, offeringId: string = '') => {
  try {
    const response: {
      _id: string
    } = await apiFetch(`partners-portal/investment-activity-create/${offeringId}`, {
      method: 'post',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const deleteInvestmentActivity = async (offeringId: string = '', investmentActivityId: string = '') => {
  try {
    return await apiFetch('partners-portal/investment-activity-delete', {
      method: 'post',
      body: JSON.stringify({
        offeringId,
        investmentActivityId,
      }),
      headers: { 'Content-Type': 'application/json' },
    });
  } catch (error) {
    console.error(error);
  }
};

export const getInvestmentActivityList = async (paramBody: GetInvestorInvestmentActivityParams) => {
  try {
    const response: InvestmentActivityListResponse = await apiFetch('investor/investment-activity-list', {
      method: 'post',
      body: JSON.stringify(paramBody),
      headers: { 'Content-Type': 'application/json' },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const updateInvestmentActivity = async (body: UpdateInvestmentActivityType) => {
  try {
    const {
      activity,
      file,
      kycState,
      email = '',
    } = body;
    const investmentActivityId = activity._id || '';
    const offeringId = activity.offeringId || '';

    const initialStatus = activity.status;
    const isReserved = initialStatus === 'Reserved';
    const isCommitmentReadyForSign = initialStatus === 'Soft commitment ready for sign';
    const isCommitmentSigned = initialStatus === 'Commitment Signed';
    const isInvoiceReceived = initialStatus === 'Invoice Received';

    const uploadedFile = await uploadFileToStorage({
      ...file,
      type: 'Documents',
    });
    if (!uploadedFile) return;

    const sendSoftCommitmentBody: SendSoftCommitmentBodyType | null = isReserved ? {
      fileSoftCommitmentContract: uploadedFile,
      isSoftCommitmentSigned: false,
      isRejected: kycState === 'Rejected',
      isKycVerified: kycState === 'Approved',
      isReserved: true,
    } : null;

    const sendSignedSoftCommitmentBody: SendSignedSoftCommitmentBodyType | null = isCommitmentReadyForSign ? {
      fileSoftCommitmentContract: uploadedFile,
      isSoftCommitmentSigned: true,
    } : null;

    const sendInvoiceBody: SendInvoiceBodyType | null = isCommitmentSigned ? {
      fileInvoice: uploadedFile,
      isInvoiceSent: true,
      email: email,
    } : null;

    const sendReceiptBody: SendReceiptBodyType | null = isInvoiceReceived ? {
      fileRecipe: uploadedFile,
      isFundSecured: true,
    } : null;

    const bodyForRequest = sendSoftCommitmentBody || sendSignedSoftCommitmentBody || sendInvoiceBody || sendReceiptBody;

    const response: {
      _id: string
    } = await apiFetch(`partners-portal/investment-activity-create/${offeringId}/${investmentActivityId}`, {
      method: 'post',
      body: JSON.stringify(bodyForRequest),
      headers: { 'Content-Type': 'application/json' },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};
