import { SxProps, Theme } from '@mui/material/styles';
import { Logo } from 'src/components';
import partnerLogo from 'src/features/investor-page/logo.png';

type PartnerLogoProps = {
  sx?: SxProps<Theme>
}

export const PartnerLogo = (props: PartnerLogoProps) => {
  const { sx } = props;

  return (
    <Logo
      href='/partner-portal/overview'
      icon={<img src={partnerLogo} alt='Pareto'/>}
      sx={{
        width: 90, height: 40, ...sx,
      }}
    />
  );
};
