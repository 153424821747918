import _ from 'lodash';
import { paths } from 'src/config';
import { TableProps } from 'src/hooks';
import { sortCustom } from 'src/utils';

import { DetailDataReportType, MarketplaceProjectReport, ReportKeyType } from '../../../../../../../../types';

export const getPathForReport = (leadId: string, report: DetailDataReportType): MarketplaceProjectReport => {
  const {
    reportType,
    reportName,
    _id,
    identifier,
  } = report;
  const loweredReportType = reportType.toLowerCase() as ReportKeyType;
  const reportUrl: Record<ReportKeyType, MarketplaceProjectReport> = {
    calculator: {
      _id,
      type: loweredReportType,
      name: reportName,
      createdAt: '20 May 2022',
      updatedAt: '20 May 2022',
      svgColor: '#004b50',
      url: paths.reports.calculatorReport(identifier),
      visibility: 'Published',
      isOpenInNewTab: true,
    },
    seller: {
      _id,
      type: loweredReportType,
      name: reportName,
      createdAt: '20 May 2022',
      updatedAt: '20 May 2022',
      svgColor: '#004B50',
      url: paths.reports.saleReport(leadId, identifier),
      visibility: 'Published',
      isOpenInNewTab: true,
    },
    bank: {
      _id,
      type: loweredReportType,
      name: reportName,
      createdAt: '20 May 2022',
      updatedAt: '20 May 2022',
      svgColor: '#B1DDE7',
      url: paths.reports.bankReport(leadId, identifier),
      visibility: 'Published',
      isOpenInNewTab: true,
    },
    'fin-upraisal': {
      _id,
      type: loweredReportType,
      name: reportName,
      createdAt: '20 May 2022',
      updatedAt: '20 May 2022',
      svgColor: '#266D7D',
      url: paths.reports.finUpraisal(identifier),
      visibility: 'Published',
      isOpenInNewTab: true,
    },
    supplier: {
      _id,
      type: loweredReportType,
      name: reportName,
      createdAt: '20 May 2022',
      updatedAt: '20 May 2022',
      svgColor: '#3698AF',
      url: paths.reports.supplierReport(leadId, identifier),
      visibility: 'Published',
      isOpenInNewTab: true,
    },
  };
  return reportUrl[loweredReportType];
};

export const reportCustomSort = (reports: MarketplaceProjectReport[], table: TableProps) => {
  const order: Record<ReportKeyType, number> = {
    calculator: 1,
    supplier: 2,
    bank: 3,
    seller: 4,
    'fin-upraisal': 5,
  };

  const groupedData = _.groupBy(reports, 'type');
  const finalArray = [];

  for (const type of Object.keys(order)) {
    const items = groupedData[type] || [];
    const sortedItems = sortCustom(items, table);
    finalArray.push(...sortedItems);
  }
  return finalArray;
};
