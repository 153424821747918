import _, { clamp } from 'lodash';
import numeral from 'numeral';
import { DEFAULT_AREA, DEFAULT_CURRENCY, EMPTY_VALUE } from 'src/constants/constants';

import { onlyNumbers } from './format-strings';

export type InputValue = string | number | null;

export function fNumber(number?: InputValue, format: string = '', returnEmptyString?: boolean) {
  const formattedValue = numeral(number)?.format(format);
  if (returnEmptyString) {
    return number ? formattedValue : '';
  }

  return formattedValue;
}

export const fNumberWithDefaultCurrency = (number?: InputValue, format: string = '') => {
  return number ? `${fNumber(number, format)} ${DEFAULT_CURRENCY}` : EMPTY_VALUE;
};

export const fNumberWithDefaultArea = (number?: InputValue, format: string = '') => {
  return number ? `${fNumber(number, format)} ${DEFAULT_AREA}` : EMPTY_VALUE;
};

export function fCurrency(number: InputValue) {
  const format = number ? numeral(number).format('$0,0.00') : '';

  return result(format, '.00');
}

export const fBigNumber = (number: number) => {
  const suffixes = ['', 'K', 'M', 'B'];
  const magnitude = Math.floor(Math.log10(Math.abs(number)) / 3);

  return magnitude > 0
    ? numeral(number / 10 ** (magnitude * 3)).format('0.[0]') + suffixes[clamp(magnitude, 0, suffixes.length - 1)]
    : number.toString();
};

export const fBigStringRange = (rangeString: string) => {
  const suffixes = ['', 'K', 'M', 'B'];

  const [start, end] = rangeString.split(' - ').map(Number);

  const startMagnitude = Math.floor(Math.log10(Math.abs(start)) / 3);
  const endMagnitude = Math.floor(Math.log10(Math.abs(end)) / 3);

  const formattedStart = startMagnitude > 0
    ? numeral(start / 10 ** (startMagnitude * 3)).format('0.[0]') + suffixes[clamp(startMagnitude, 0, suffixes.length - 1)]
    : start.toString();

  const formattedEnd = endMagnitude > 0
    ? numeral(end / 10 ** (endMagnitude * 3)).format('0.[0]') + suffixes[clamp(endMagnitude, 0, suffixes.length - 1)]
    : end.toString();

  return `${formattedStart} - ${formattedEnd}`;
};

export const fPercent = (number?: InputValue) => {
  if (!number) return EMPTY_VALUE;
  const format = numeral(Number(number) / 100).format('0.00%');
  return result(format, '.00');
};

export function fShortenNumber(number: InputValue) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number: InputValue) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format: string, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export const showEmptyValue = (value?: string | number | null) => {
  if (!_.isNaN(Number(value)) && value) {
    return +value ? value : EMPTY_VALUE;
  }
  return value ? value : EMPTY_VALUE;
};

export const convertBytesToKB = (bytes: number = 1) => {
  return (bytes / 1024).toFixed(1);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNumbersAreEqualInObj = (obj1: Record<string, any>, obj2: Record<string, any>) => {
  const commonKeys = _.intersection(Object.keys(obj1), Object.keys(obj2));
  for (const key of commonKeys) {
    if (!isTwoNumbersAreEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};

export const isTwoNumbersAreEqual = (num1: number | string, num2: number | string) => {
  const roundedNum1 = _.round(Number(onlyNumbers(num1.toString())), 2);
  const roundedNum2 = _.round(Number(onlyNumbers(num2.toString())), 2);
  return roundedNum1 === roundedNum2;
};
