import { Stack } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { debounce } from 'lodash';
import { ChangeEvent, memo, MouseEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CustomTab, TableCustom, TableFiltersResult, TablePaginationCustom, TableToolbar, TabType } from 'src/components';
import { chipVariants, projectsOfferingColor } from 'src/constants/constants';
import { useTable } from 'src/hooks';
import { AppThunk } from 'src/store';
import { GetOngoingOfferingsParams } from 'src/store/partners-portal/types';

import { OfferingResponse, ProjectsOffering } from '../../../../types';
import { TableHeadType } from '../leads-page';
import { ProjectsOfferingTableRow } from './components';
import { _projectTypeList, defaultFilters } from './mock-data';
import { TableFilters, TableFilterValue } from './types';

type ProjectsOfferingPageProps = {
  showPublishedDate?: boolean;
  showDetailsBtn?: boolean;
  showPartnerFee?: boolean;
  showProjectTypeSelect?: boolean;
  title?: string;
  tableHead: TableHeadType[];
  loadFn: (params: GetOngoingOfferingsParams) => Promise<OfferingResponse | undefined>;
  unlistProjectsOffering(projectOfferId: string): AppThunk;
  onDetailPush: (id: string, pathKey?: string) => void;
}

export const ProjectsOfferingPage = memo<ProjectsOfferingPageProps>((props) => {
  const {
    showDetailsBtn,
    showPublishedDate,
    showPartnerFee,
    showProjectTypeSelect,
    title,
    tableHead,
    loadFn,
    unlistProjectsOffering,
    onDetailPush,
  } = props;

  const [tableData, setTableData] = useState<OfferingResponse>({
    page: 1,
    total: 0,
    data: [],
  });
  const [filters, setFilters] = useState(defaultFilters);

  const [correspondingBody, setCorrespondingBody] = useState<GetOngoingOfferingsParams>({
    page: 1,
    limit: 5,
    status: 'All',
  });

  const table = useTable();
  const dispatch = useDispatch();

  const dataFiltered: ProjectsOffering[] = tableData.data || [];
  const dataInPage = dataFiltered?.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage,
  );

  const loadData = useCallback(async (params: GetOngoingOfferingsParams) => {
    const data = await loadFn(params);
    if (data) {
      setTableData(data);
    }
  }, [loadFn]);

  useEffect(() => {
    loadData({
      page: 1,
      limit: 5,
      status: 'All',
    });
  }, [loadData]);

  const updateRequestBody = useCallback((newBodyPart: Partial<GetOngoingOfferingsParams>) => {
    const newBody: GetOngoingOfferingsParams = {
      ...correspondingBody,
      ...newBodyPart,
    };
    loadData(newBody);
    setCorrespondingBody(newBody);
  }, [correspondingBody, loadData]);

  const TABS: TabType[] = useMemo(() => {
    return [
      {
        value: 'All',
        label: 'All',
        count: tableData?.counters?.All,
      },
      {
        value: 'Ongoing Offerings',
        label: 'Ongoing Offerings',
        count: tableData?.counters?.['Ongoing Offerings'],
      },
      {
        value: 'Unlisted',
        label: 'Unlisted',
        count: tableData?.counters?.Unlisted,
      },
    ];
  }, [tableData?.counters]);

  const handleFilters = useCallback((name: keyof TableFilters, value: TableFilterValue) => {
    updateRequestBody({
      [name]: value,
      page: 1,
      limit: 5,
    });
    table.onResetPage();
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, [table, updateRequestBody]);

  const onFiltersDeleteHandler = useCallback((filterKey: keyof TableFilters, valueToDelete: TableFilterValue) => {
    const filterValue = filters[filterKey];
    let newValue = defaultFilters[filterKey];
    if (Array.isArray(filterValue)) {
      newValue = filters.projectType.filter((item) => item !== valueToDelete);
    }
    handleFilters(filterKey, newValue);
  }, [filters, handleFilters]);

  const handleFilterOfferStatus = useCallback((_: SyntheticEvent, newValue: string) => {
    handleFilters('status', newValue);
  }, [handleFilters]);

  const canReset =
    !!filters.text ||
    filters.projectType?.length > 0 ||
    filters.status !== 'All';

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const noDataFound = (dataFiltered?.length === 0 && canReset) || dataFiltered?.length === 0;

  const handleDeleteRow = useCallback((id: string) => () => {
    dispatch(unlistProjectsOffering(id));
    table.onUpdatePageDeleteRow(dataInPage?.length);
  }, [dataInPage?.length, dispatch, table, unlistProjectsOffering]);

  const handleMoveToNextPage = useCallback((id: string, pathKey: string) => () => {
    onDetailPush(id, pathKey);
  }, [onDetailPush]);

  const onChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    updateRequestBody({
      limit: +event.target.value,
      page: 1,
    });
    table.onChangeRowsPerPage(event);
  };

  const onPageChange = debounce((event: MouseEvent<HTMLButtonElement> | null, page: number) => {
    updateRequestBody({ page: page + 1 });
    table.onChangePage(event, page);
  }, 200);

  const onDetailsClickHandler = useCallback((id: string) => () => {
    onDetailPush(id);
  }, [onDetailPush]);

  return (
    <>
      {title &&
        <Typography variant='h4' sx={{ mb: 5 }}>
          {title}
        </Typography>
      }

      <Card>
        <CustomTab
          tabs={TABS}
          defaultTabValue='All'
          currentTabValue={filters.status}
          handleTabOnChange={handleFilterOfferStatus}
        />
        <TableToolbar
          selectValue={filters.projectType}
          selectOptions={_projectTypeList}
          selectedOptions={filters.projectType}
          onFilters={handleFilters}
          selectLabel='Project type'
          selectKey={showProjectTypeSelect ? 'projectType' : undefined}
          searchFieldValue={filters.text}
          searchFieldKey='text'
        />
        {canReset && (
          <Stack
            sx={{
              paddingLeft: '20px',
              paddingBottom: '16px',
            }}
          >
            <TableFiltersResult
              filters={filters}
              defaultFilters={defaultFilters}
              onFiltersDeleteHandler={onFiltersDeleteHandler}
              onResetFilters={handleResetFilters}
              results={tableData.total}
              chipVariants={chipVariants}
              chipColors={projectsOfferingColor}
            />
          </Stack>
        )}

        <TableCustom
          headLabels={tableHead}
          table={table}
          rowCount={tableData.total}
          noDataFound={noDataFound}
          bodyCells={(
            <>
              {dataFiltered?.map((row) => (
                <ProjectsOfferingTableRow
                  key={row._id}
                  row={row}
                  onViewRow={handleMoveToNextPage(row.projectId || row._id, 'details')}
                  onDeleteRow={handleDeleteRow(row._id)}
                  detailsOnClickHandler={onDetailsClickHandler(row._id)}
                  showPublishedDate={showPublishedDate}
                  showDetailsBtn={showDetailsBtn}
                  showPartnerFee={showPartnerFee}
                />
              ))}
            </>
          )}
        />

        <TablePaginationCustom
          count={tableData.total}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={onPageChange}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Card>
    </>
  );
});
