import { format, formatDistance, getTime } from 'date-fns';

type InputValue = Date | string | number | null | undefined;

export function fDate(date: InputValue, newFormat: string = 'dd MMM yyyy') {
  return date ? format(new Date(date), newFormat) : '';
}

export function fDateTime(date: InputValue, newFormat: string = 'dd MMM yyyy p') {
  return date ? format(new Date(date), newFormat) : '';
}

export const fDistanceDate = (firstDate: InputValue, secondDate: Date = new Date()) => {
  return firstDate ? formatDistance(new Date(firstDate), secondDate, { addSuffix: false }) : '';
};

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

