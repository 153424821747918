import { Grid, Stack } from '@mui/material';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import _, { debounce } from 'lodash';
import { ChangeEvent, memo, MouseEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomTab, TableCustom, TableFiltersResult, TablePaginationCustom, TableToolbar, TabType } from 'src/components';
import { paths } from 'src/config';
import { chipVariants, projectTypeColor, stageColor } from 'src/constants/constants';
import { useRouter, useTable } from 'src/hooks';
import { RootState } from 'src/store';
import { deleteFinancingThunk, loadFinancing } from 'src/store/finance';
import { sortCustom } from 'src/utils';

import { Finance, StageType } from '../../../../types';
import { _projectTypeList } from '../projects-offering-page';
import { FinancingTableRow } from './components';
import { defaultFilters, TABLE_HEAD } from './mock-data';
import { FinancingBodyParams, TableFilters, TableFilterValue } from './types';


export const FinancePage = memo(() => {
  const [filters, setFilters] = useState(defaultFilters);
  const [correspondingBody, setCorrespondingBody] = useState<FinancingBodyParams>({
    page: 1,
    limit: 5,
    stage: 'All',
  });
  const table = useTable();
  const router = useRouter();
  const finances = useSelector((state: RootState) => state.Finance.finances);
  const counters = useSelector((state: RootState) => state.Finance.counters);
  const metadata = useSelector((state: RootState) => state.Finance.metadata);
  const isDemo = useSelector((state: RootState) => state.App.config?.isDemo);
  const dispatch = useDispatch();

  const dataFiltered: Finance[] = isDemo ? applyFilter(sortCustom(finances, table), filters) : finances;
  const dataInPage = dataFiltered?.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage,
  );

  useEffect(() => {
    dispatch(loadFinancing({
      page: 1,
      limit: 5,
      stage: 'All',
    }));
  }, [dispatch]);

  const updateFinancing = useCallback((newBodyPart: Partial<FinancingBodyParams>) => {
    const newBody: FinancingBodyParams = {
      ...correspondingBody,
      ...newBodyPart,
    };
    dispatch(loadFinancing(newBody));
    setCorrespondingBody(newBody);
  }, [correspondingBody, dispatch]);

  const getFinancingLength = useCallback((stage: StageType) => {
    return finances?.filter((item) => item.stage === stage)?.length;
  }, [finances]);

  const TABS: TabType[] = useMemo(() => {
    return [
      {
        value: 'All',
        label: 'All',
        count: isDemo ? finances?.length : counters.All,
      },
      {
        value: 'Lead',
        label: 'Lead',
        count: isDemo ? getFinancingLength('Lead') : counters.Lead,
      },
      {
        value: 'In Progress',
        label: 'In Progress',
        count: isDemo ? getFinancingLength('In Progress') : counters['In Progress'],
      },
      {
        value: 'Done',
        label: 'Done',
        count: isDemo ? getFinancingLength('Done') : counters.Done,
      },
    ];
  }, [isDemo, finances?.length, counters, getFinancingLength]);

  const handleFilters = useCallback((name: keyof TableFilters, value: TableFilterValue) => {
    updateFinancing({
      [name]: value,
      page: 1,
      limit: 5,
    });
    table.onResetPage();
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, [table, updateFinancing]);

  const handleFilterStatus = useCallback((_: SyntheticEvent, newValue: string) => {
    handleFilters('stage', newValue);
  }, [handleFilters]);

  const canReset =
    !!filters.text ||
    filters.projectType?.length > 0 ||
    filters.stage !== 'All';

  const handleResetFilters = useCallback(() => {
    updateFinancing(defaultFilters);
    setFilters(defaultFilters);
  }, [updateFinancing]);

  const noDataFound = (finances?.length === 0 && canReset) || dataFiltered?.length === 0;

  const onPageChange = debounce((event: MouseEvent<HTMLButtonElement> | null, page: number) => {
    updateFinancing({ page: page + 1 });
    table.onChangePage(event, page);
  }, 200);

  const onChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    updateFinancing({
      limit: +event.target.value,
      page: 1,
    });
    table.onChangeRowsPerPage(event);
  };

  const onRowClickHandler = (id: string, pathKey: keyof typeof paths.financing, tab: string = 'overview') => () => {
    const currentPath = paths.financing[pathKey];
    router.push(typeof currentPath === 'function' ? currentPath(id, tab) : currentPath);
  };

  const onFiltersDeleteHandler = useCallback((filterKey: keyof TableFilters, valueToDelete: TableFilterValue) => {
    const filterValue = filters[filterKey];
    let newValue = defaultFilters[filterKey];
    if (Array.isArray(filterValue)) {
      newValue = filters.projectType.filter((item) => item !== valueToDelete);
    }
    handleFilters(filterKey, newValue);
  }, [filters, handleFilters]);

  const handleDeleteRow = useCallback((id: string) => () => {
    dispatch(deleteFinancingThunk(id));
    table.onUpdatePageDeleteRow(dataInPage?.length);
  }, [dispatch, table, dataInPage?.length]);

  const onSortChange = debounce((id: string) => {
    const {
      orderBy,
      order,
    } = table;
    const isAsc = orderBy === id && order === 'asc';
    updateFinancing({
      sort: id,
      sortType: isAsc ? 'desc' : 'asc',
    });
    table.onSort(id);
  }, 200);

  return (
    <Container maxWidth={false}>
      <Grid container style={{ marginBottom: '40px' }}>
        <Grid item xs={6}>
          <Typography variant='h4'>
            Debt
          </Typography>
        </Grid>
      </Grid>
      <Card>
        <CustomTab
          tabs={TABS}
          defaultTabValue='All'
          currentTabValue={filters.stage}
          handleTabOnChange={handleFilterStatus}
        />
        <TableToolbar
          selectValue={filters.projectType}
          selectOptions={_projectTypeList}
          selectedOptions={filters.projectType}
          onFilters={handleFilters}
          selectLabel='Project type'
          selectKey='projectType'
          searchFieldValue={filters.text}
          searchFieldKey='text'
        />
        {canReset && (
          <Stack
            sx={{
              paddingLeft: '20px',
              paddingBottom: '16px',
            }}
          >
            <TableFiltersResult
              filters={filters}
              defaultFilters={defaultFilters}
              onFiltersDeleteHandler={onFiltersDeleteHandler}
              onResetFilters={handleResetFilters}
              results={isDemo ? dataInPage?.length : metadata.total}
              chipVariants={chipVariants}
              chipColors={{ ...stageColor, ...projectTypeColor }}
            />
          </Stack>
        )}

        <TableCustom
          headLabels={TABLE_HEAD}
          table={table}
          rowCount={isDemo ? finances?.length : metadata.total}
          noDataFound={noDataFound}
          onSort={onSortChange}
          bodyCells={(
            <>
              {(isDemo ? dataInPage : finances)?.map((row) => (
                <FinancingTableRow
                  key={row._id}
                  stageColor={stageColor}
                  row={row}
                  onViewRow={onRowClickHandler(row._id, 'details')}
                  onDeleteRow={handleDeleteRow(row._id)}
                />
              ))}
            </>
          )}
        />

        <TablePaginationCustom
          count={isDemo ? dataFiltered.length : metadata.total}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={onPageChange}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Card>
    </Container>
  );
});

const applyFilter = (data: Finance[], filters: TableFilters) => {
  let resultData = _.cloneDeep(data);
  const {
    text,
    projectType,
    stage,
  } = filters;

  if (text) {
    resultData = resultData?.filter((item) => {
      return item.name?.toLowerCase().includes(text.toLowerCase()) ||
        item.type?.toString().includes(text.toString()) ||
        item.totalFunds?.toString().includes(text.toString()) ||
        item.debt?.toString().includes(text.toString()) ||
        item.equity.toString().includes(text.toString()) ||
        item.structure.toString().includes(text.toString()) ||
        item.lastActivityDate.toString().includes(text.toString()) ||
        item.stage.toString().includes(text.toString()) ||
        item.lastActivity.toString().includes(text.toString());
    });
  }

  if (projectType?.length > 0) {
    resultData = resultData?.filter(item => projectType.includes(item.type));
  }

  if (stage !== 'All') {
    resultData = resultData?.filter((item) => item.stage === stage);
  }

  return resultData;
};
