import { alpha, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ChangeEvent, useMemo, useState } from 'react';
import { PdfFileIcon } from 'src/assets';
import { ColorCircle, CustomSelect, DetailsList, DrawerCustom, generateSummaryField, Iconify, ItemType, UploadFile } from 'src/components';
import { UseBooleanReturnType } from 'src/hooks';
import { updateInvestmentActivity } from 'src/store/investor';
import { fDate, fNumberWithDefaultCurrency, fPercent, isEmailValid, openInNewTab } from 'src/utils';

import { FileForUpload, MarketplaceProjectActivity } from '../../../../../../../../../types';

type ActivityDrawerProps = {
  drawer: UseBooleanReturnType;
  activity: MarketplaceProjectActivity;
  isPartnerActivity?: boolean;
  refetchFunction: VoidFunction;
}

export const ActivityDrawer = (props: ActivityDrawerProps) => {
  const {
    drawer,
    activity,
    isPartnerActivity,
    refetchFunction,
  } = props;
  const initialStatus = activity.status;
  const isReserved = initialStatus === 'Reserved';
  const kycValue = isReserved ? '' : (activity.isKycVerified ? 'Approved' : 'Rejected');

  const [kycState, setKycState] = useState<string>(kycValue);
  const [email, setEmail] = useState<string>('');
  const [file, setFile] = useState<FileForUpload | null>(null);
  const theme = useTheme();

  const isSendInvoiceDisabled = !isEmailValid(email) || !file;
  const isCommitmentReadyForSign = initialStatus === 'Soft commitment ready for sign';
  const isCommitmentSigned = initialStatus === 'Commitment Signed';
  const isInvoiceReceived = initialStatus === 'Invoice Received';
  const isAllFilesAttached = initialStatus === 'Secured Capital';

  const kycBorderSx = {
    borderWidth: 2,
    borderColor: theme.palette.text.primary,
  };

  const toggleKycApproved = (value: string) => () => {
    setKycState(value);
  };

  const onEmailChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setEmail(value);
  };

  const onSubmitClickHandler = async () => {
    if (file?.file) {
      const response = await updateInvestmentActivity({
        activity: activity,
        kycState,
        file,
        email,
      });
      if (response?._id) {
        refetchFunction();
        drawer.onFalse();
      }
    }
  };

  const onFileClickHandler = (fileUr: string = '') => () => {
    openInNewTab(fileUr);
  };

  const colorsConfig = useMemo(() => {
    const colors: Record<string, string> = {
      'Reserved': theme.palette.secondary.main,
      'Soft commitment ready for sign': theme.palette.success.main,
      'Commitment Signed': theme.palette.success.main,
      'Invoice Sent': theme.palette.info.main,
      'Invoice Received': theme.palette.info.main,
      'Secured Capital': theme.palette.primary.main,
      'Rejected': theme.palette.success.main,
    };
    return colors;
  }, [theme.palette.info.main, theme.palette.primary.main, theme.palette.secondary.main, theme.palette.success.main]);

  const projectFields: ItemType[] = useMemo(() => {
    return generateSummaryField({
      project: <Button
        sx={{
          color: theme.palette.primary.main,
          p: 0,
        }}
        variant='text'
        size='small'
      >
        {activity.projectName}
      </Button>,
      investmentType: 'Equity',
      amount: fNumberWithDefaultCurrency(activity?.amount),
    });
  }, [activity?.amount, activity.projectName, theme.palette.primary.main]);

  const investorFields: ItemType[] = useMemo(() => {
    return generateSummaryField({
      investor: activity.investorName,
      email: activity.investorEmail,
      phone: activity.investorPhone,
    });
  }, [activity.investorEmail, activity.investorName, activity.investorPhone]);

  const receiptFields: ItemType[] = useMemo(() => {
    const commitment = activity.fileSoftCommitmentContract?.url ? {
      commitment: <Button
        sx={{
          color: theme.palette.primary.main,
          p: 0,
        }}
        onClick={onFileClickHandler(activity.fileSoftCommitmentContract.url)}
        variant='text'
        size='small'
      >
        Soft commitment contract.pdf
      </Button>,
    } : {};
    const invoice = activity.fileInvoice?.url ? {
      invoice: <Button
        sx={{
          color: theme.palette.primary.main,
          p: 0,
        }}
        onClick={onFileClickHandler(activity.fileInvoice.url)}
        variant='text'
        size='small'
      >
        Invoice.pdf
      </Button>,
    } : {};
    const receipt = activity.fileRecipe?.url ? {
      receipt: <Button
        sx={{
          color: theme.palette.primary.main,
          p: 0,
        }}
        onClick={onFileClickHandler(activity.fileRecipe.url)}
        variant='text'
        size='small'
      >
        Receipt.pdf
      </Button>,
    } : {};
    return generateSummaryField({
      commission: `${fNumberWithDefaultCurrency(activity.commission)} (${fPercent(activity.commissionPercent)})`,
      amlKyc: isReserved
        ? <Stack direction='row' gap={1}>
          <Button
            onClick={toggleKycApproved('Rejected')}
            startIcon={<ColorCircle sx={{ mr: 0 }} color={theme.palette.error.main}/>}
            sx={kycState === 'Rejected' ? kycBorderSx : {}}
            size='small'
            variant='outlined'
          >
            Rejected
          </Button>
          <Button
            onClick={toggleKycApproved('Approved')}
            startIcon={<ColorCircle sx={{ mr: 0 }} color={theme.palette.success.main}/>}
            sx={kycState === 'Approved' ? kycBorderSx : {}}
            size='small'
            variant='outlined'
          >
            Approved
          </Button>
        </Stack>
        : <Chip
          variant='soft'
          size='small'
          label={kycState ? 'Approved' : 'Rejected'}
          color={kycState ? 'success' : 'error'}
        />,
      ...commitment,
      ...invoice,
      ...receipt,
    });
  }, [activity.commission, activity.commissionPercent, activity.fileInvoice?.url, activity.fileRecipe?.url, activity.fileSoftCommitmentContract?.url, isReserved, kycBorderSx, kycState, theme.palette.error.main, theme.palette.primary.main, theme.palette.success.main]);

  const uploadFileHandler = (files: FileForUpload[]) => {
    const filename: Record<string, string> = {
      'Reserved': 'Soft commitment contract',
      'Soft commitment ready for sign': 'Signed Soft Commitment',
      'Commitment Signed': 'Invoice',
      'Invoice Sent': 'Receipt',
      'Invoice Received': 'Receipt',
    };

    const updatedFile: FileForUpload = {
      ...files[0],
      filename: filename[initialStatus as string],
    };
    setFile(files.length > 0 ? updatedFile : null);
  };

  return (
    <>
      <DrawerCustom
        containerSx={{ width: 480 }}
        open={drawer.value}
        onCloseDrawerHandler={drawer.onFalse}
        headChildren={
          <CustomSelect
            showCircle
            controlSx={{
              bgcolor: 'background.neutral',
              height: 30,
              '& .MuiInputBase-root': { height: 30 },
              '& .MuiSvgIcon-root': { height: 0 },
            }}
            configKey='colors'
            value={[initialStatus]}
            colorsConfig={colorsConfig}
            options={[]}
          />
        }
        bodyChildren={
          <Stack gap={3}>
            <Typography variant='h6'>Activity Info</Typography>
            <DetailsList
              fields={projectFields}
              boxConfig={{
                p: 0,
                gap: 3,
              }}
              labelContainerSx={{
                fontSize: 12,
                width: 105,
              }}
            />
            <Divider/>
            <DetailsList
              fields={investorFields}
              boxConfig={{
                p: 0,
                gap: 3,
              }}
              labelContainerSx={{
                fontSize: 12,
                width: 105,
              }}
            />
            <Divider/>
            <DetailsList
              fields={receiptFields}
              boxConfig={{
                p: 0,
                gap: 3,
              }}
              labelContainerSx={{
                fontSize: 12,
                width: 105,
              }}
            />
            {!isAllFilesAttached &&
              <>
                <Divider/>
                <Stack gap={3}>
                  {!isPartnerActivity && (isCommitmentReadyForSign || isInvoiceReceived) &&
                    <>
                      <Stack direction='row' gap={0.5} alignItems='center'>
                        <Iconify
                          width={16}
                          icon='eva:alert-circle-fill'
                          color={theme.palette.text.disabled}
                        />
                        <Typography variant='caption' color={theme.palette.text.secondary}>
                          {isCommitmentReadyForSign && 'Download the Soft Commitment, sign it, and upload it to the form below.'}
                          {isInvoiceReceived && 'Pay it, and upload receipt to the form below.'}
                        </Typography>
                      </Stack>
                      <Stack
                        spacing={2}
                        onClick={onFileClickHandler(isCommitmentReadyForSign ? activity.fileSoftCommitmentContract?.url : activity.fileInvoice?.url)}
                        direction='row'
                        alignItems='center'
                        sx={{
                          py: 1,
                          px: 1.5,
                          borderRadius: 1,
                          cursor: 'pointer',
                          border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.16)}`,
                        }}
                      >
                        <ListItemIcon>
                          <PdfFileIcon/>
                        </ListItemIcon>
                        <ListItemText
                          primary={isCommitmentReadyForSign ? 'Signed Soft Commitment.pdf' : 'Invoice.pdf'}
                          secondary={`
                      ${isCommitmentReadyForSign ? activity.fileSoftCommitmentContract?.size : activity.fileInvoice?.size} ·
                      ${isCommitmentReadyForSign ? fDate(activity.fileSoftCommitmentContract?.uploadedAt) : fDate(activity.fileInvoice?.uploadedAt)}
                      `}
                          sx={{
                            '& .MuiTypography-body1': {
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            },
                          }}
                        />
                        <IconButton
                          size='small'
                          onClick={onFileClickHandler(isCommitmentReadyForSign ? activity.fileSoftCommitmentContract?.url : activity.fileInvoice?.url)}
                        >
                          <Iconify icon='eva:cloud-download-fill' width={16}/>
                        </IconButton>
                      </Stack>
                    </>
                  }
                  {!isAllFilesAttached &&
                    <UploadFile
                      disabled={!!file || (isReserved && !kycState)}
                      containerSx={{ width: '100%' }}
                      innerContainerSx={{ p: 2.5 }}
                      uploadTitle={isPartnerActivity
                        ? (isReserved ? 'Upload soft commitment contract' : 'Upload Invoice')
                        : (isReserved ? 'Upload signed soft commitment ' : 'Upload Receipt')
                      }
                      titleStyleConfig={{
                        variant: 'body2',
                        color: theme.palette.text.disabled,
                        mt: 0.5,
                      }}
                      uploadedFiles={file ? [file] : []}
                      uploadFileHandler={uploadFileHandler}
                      fileIconSx={{ mr: 0 }}
                      customIcon={
                        <Iconify
                          icon='eva:cloud-upload-fill'
                          width={40}
                          color={theme.palette.text.disabled}
                        />
                      }
                    />
                  }
                  {isPartnerActivity && isReserved && !kycState &&
                    <Stack direction='row' gap={0.5} alignItems='center'>
                      <Iconify
                        width={16}
                        icon='eva:alert-circle-fill'
                        color={theme.palette.text.disabled}
                      />
                      <Typography variant='caption' color={theme.palette.text.secondary}>
                        Update AML / KYC to change status
                      </Typography>
                    </Stack>
                  }
                </Stack>
              </>
            }
          </Stack>
        }
        footerChildrenSx={isPartnerActivity && isCommitmentSigned ? { borderTop: `1px solid ${theme.palette.divider}` } : undefined}
        footerChildren={
          <>
            {isPartnerActivity && isReserved &&
              <Button
                variant='contained'
                size='large'
                fullWidth
                disabled={!file}
                onClick={onSubmitClickHandler}
              >
                Confirm
              </Button>
            }
            {!isPartnerActivity && !isAllFilesAttached &&
              <Button
                variant='contained'
                size='large'
                fullWidth
                disabled={!file}
                onClick={onSubmitClickHandler}
              >
                Continue
              </Button>
            }
            {isPartnerActivity && isCommitmentSigned &&
              <Stack direction='row' gap={2} alignItems='center' sx={{ flex: 1 }}>
                <TextField
                  value={email}
                  onChange={onEmailChangeHandler}
                  label='* Investor email'
                  variant='outlined'
                  size='small'
                  sx={{ flex: 1 }}
                  error={email ? !isEmailValid(email) : false}
                  helperText={(email && !isEmailValid(email)) ? 'Email must be a valid email address' : ''}
                />
                <Button
                  sx={{ textWrap: 'nowrap' }}
                  variant='contained'
                  onClick={onSubmitClickHandler}
                  disabled={isSendInvoiceDisabled}
                >
                  Send Invoice
                </Button>
              </Stack>
            }
          </>
        }
      />
    </>
  );
};
