import Stack from '@mui/material/Stack';
import { ReactNode, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { rolesAccess } from 'src/config';
import FinancingDebtRequest from 'src/features/debt-request/financing-debt-request';
import UploadFinancingAgreementDocuments from 'src/features/debt-request/upload-financing-agreement-documents';
import { Financial } from 'src/features/financing-details/components';
import { LeadOverview, Team } from 'src/features/lead-details';
import { Files } from 'src/features/marketplace-details/components/project-details/components/files/files';
import { Offering as MarketplaceProjectOffering } from 'src/features/marketplace-details/components/project-details/components/offering/offering';
import { Overview as MarketplaceProjectOverview } from 'src/features/marketplace-details/components/project-details/components/overview/overview';
import { Reports } from 'src/features/marketplace-details/components/project-details/components/reports/reports';
import { OpportunityPublish } from 'src/features/partner-portal/projects/opportunities/opportunity-publish/opportunity-publish';
import { Offering } from 'src/features/project-offering-details/components';
import { Assets } from 'src/features/project-preparation-details/components/assets/assets';
import { Overview, OverviewProps, useDefaultOverview } from 'src/features/research-details';
import { SaleProject } from 'src/features/sell-details';
import { PreliminaryQuotationRequest } from 'src/features/tendering-details/components/preliminary-quotation-request';
import { TenderingSuppliers } from 'src/features/tendering-details/components/suppliers/suppliers';
import { useRestrictTabs, useRouter } from 'src/hooks';
import { RootState } from 'src/store';
import { getFileSections, setFileSections } from 'src/store/app';
import { loadFinancialData } from 'src/store/financial';
import { assigneeTeamMember, loadLeadDetails } from 'src/store/lead';
import { addDetailsViewDataAssignee, removeDetailsViewDataAssignee, setIsLoading, updateLeadDetailViewData } from 'src/store/storage';
import { formatFullName, genStepsByLeadDetails, isPrevStepsCompleted } from 'src/utils';

import { AssigneeType, AssignmentMember, LabelColor, MarketplaceProjectFile, Scenario, StatusType, TeamRoleType, User } from '../../../../types';
import { CrumbType } from '../breadcrumbs-custom';
import { formsWithHiddenTabs, LabelTabType, TabType } from '../custom-tab';
import { HelpCloudProps } from '../help-cloud';
import { LabelVariant } from '../label';
import { ProjectToolbar } from '../project-toolbar';
import { Report, useLeadCalculation } from '../report';
import { ItemType } from '../summary';
import { TabContextCustom, TabPanelsType } from '../tab-context-custom';
import { RaiseMonitoring } from './components';

export type DetailViewTabPanelsType = TabPanelsType & {
  tabKey: LabelTabType;
}

export type DetailViewTabType = TabType & {
  value: LabelTabType;
  label: string;
  hidden?: boolean;
}

export interface DetailViewProps {
  showData?: boolean;
  crumbs: CrumbType[];
  scenario?: Scenario;
  toolbarTitle?: string;
  toolbarBadgeTitle?: string;
  showToolbarBadge?: boolean;
  showOverviewCloud?: boolean;
  toolbarBadgeVariant?: LabelVariant;
  toolbarBadgeColor?: LabelColor;
  toolbarChildren?: ReactNode;
  overviewProps?: OverviewProps;
  cloudProps?: HelpCloudProps;
  componentsViews?: 'default' | 'lead' | 'marketplaceProject';
  noteSection?: string;
  customSummaryItemsOnOverview?: ItemType[];
  customDetailsFc?: VoidFunction;
  isPartnerPortal?: boolean;
  isMarketplaceProject?: boolean;
  isPartnerOffering?: boolean;
  forceLeadDetailsDemo?: boolean;
  useCustomFiles?: boolean;
  customFiles?: MarketplaceProjectFile[];
}

export const DetailView = (props: DetailViewProps) => {
  const {
    showToolbarBadge = false,
    showOverviewCloud = false,
    forceLeadDetailsDemo = false,
    useCustomFiles = false,
    showData = true,
    componentsViews = 'default',
    crumbs = [],
    customFiles = [],
    customSummaryItemsOnOverview = [],
    toolbarBadgeVariant,
    toolbarTitle,
    toolbarBadgeTitle,
    toolbarBadgeColor,
    toolbarChildren,
    scenario: scenarioFromProps,
    overviewProps,
    noteSection,
    cloudProps,
    isPartnerPortal,
    isMarketplaceProject,
    isPartnerOffering,
    customDetailsFc,
  } = props;

  const {
    id: idFromParams,
    tab: tabFromParams,
  } = useParams<{
    id: string,
    tab: string,
  }>();

  const [currentTab, setCurrentTab] = useState<string>(tabFromParams);

  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const isLoading = useSelector((state: RootState) => state.Storage.isLoading);
  const notes = useSelector((state: RootState) => state.Storage.notes);
  const router = useRouter();
  const dispatch = useDispatch();

  const scenario = detailsData?.project?.scenario || scenarioFromProps;
  const lead = detailsData?.lead;
  const assets = detailsData?.assets;
  const tendering = detailsData?.tendering;
  const offeringDetails = detailsData?.offeringDetails;

  const userList = useMemo(() => {
    const users = detailsData?.users || [];
    return [...users];
  }, [detailsData?.users]);

  const currentProjectsOffer = useSelector((state: RootState) => state.ProjectsOffering.currentProjectsOffer);
  const financialFromStore = useSelector((state: RootState) => state.Financial.financial);
  const financialSheet = useSelector((state: RootState) => state.Financial.financialSheet);
  const reducedMenu = useSelector((state: RootState) => state.App.config?.reducedMenu);
  const role = useSelector((state: RootState) => state.Auth.user?.role || 'user');

  useEffect(() => {
    if (customDetailsFc) {
      customDetailsFc?.();
      return;
    }
    dispatch(setFileSections([]));
    dispatch(loadLeadDetails(idFromParams, forceLeadDetailsDemo, detailViewData => {
      dispatch(loadFinancialData(detailViewData?.project?._id));
      dispatch(getFileSections(detailViewData?.lead?._id, detailViewData.project?._id));
    }));
  }, [customDetailsFc, dispatch, forceLeadDetailsDemo, idFromParams]);

  useEffect(() => {
    setCurrentTab(tabFromParams);
  }, [tabFromParams]);

  const tabs = useMemo<DetailViewTabType[]>(() => {
    const paramsForPrevStepFunc = {
      lead,
      assets,
      financing: financialFromStore,
      tendering,
    };

    const distribution = lead?.distribution;
    const offeringTab: DetailViewTabType = {
      value: 'offering',
      label: 'Equity monitoring',
      disabled: reducedMenu || !distribution?.equityFinancing || !isPrevStepsCompleted('equity', paramsForPrevStepFunc),
    };

    const list: DetailViewTabType[] = [
      {
        value: 'overview',
        label: 'Overview',
      },
      {
        value: 'reports',
        label: 'Reports',
      },
      {
        value: 'team',
        label: 'Team',
        hidden: isPartnerPortal,
      },
      {
        value: 'files',
        label: 'Files',
      },
      {
        value: 'assets',
        label: 'Assets',
        hidden: isPartnerPortal,
      },
      {
        value: 'suppliers',
        label: 'Suppliers',
        hidden: isMarketplaceProject || isPartnerPortal || isPartnerOffering,
        disabled: reducedMenu || !distribution?.tendering || !isPrevStepsCompleted('suppliers', paramsForPrevStepFunc),
      },
      {
        value: 'financial',
        label: 'Debt',
        hidden: isMarketplaceProject || isPartnerPortal || isPartnerOffering,
        disabled: !distribution?.debtFinancing || !isPrevStepsCompleted('debt', paramsForPrevStepFunc),
      },
      {
        ...offeringTab,
        hidden: isMarketplaceProject || isPartnerPortal,
      },
      {
        value: 'raise-monitoring',
        label: 'Sell monitoring',
        disabled: !distribution?.sell || !isPrevStepsCompleted('sell', paramsForPrevStepFunc),
        hidden: isPartnerPortal || isMarketplaceProject || isPartnerOffering,
      },
    ];

    return list;
  }, [assets, financialFromStore, isMarketplaceProject, isPartnerOffering, isPartnerPortal, lead, reducedMenu, tendering]);
  const filteredTabs = tabs.filter(t => !t.hidden && rolesAccess[role]?.allowedTabs?.includes(t.value));

  const { reportProps: leadReportProps } = useLeadCalculation(lead);
  useRestrictTabs(tabs, currentTab);

  const {
    summaryItems,
    leadSummaryItems,
    learnMoreItems,
    landFields,
  } = useDefaultOverview(lead, scenario);

  const handleAssign = useCallback((designation: TeamRoleType, assignedUser?: AssignmentMember) => async (user: User) => {
    dispatch(setIsLoading(true));
    const isSameUser = assignedUser?.profileLink === user.id;
    if (lead?._id) {
      const members = userList.filter(user => user.designation === designation);
      const newUser: AssigneeType = {
        designation,
        profileLink: user.id,
        name: formatFullName(user.name, user.surname),
        isNew: true,
      };
      members.push(isSameUser ? assignedUser : newUser);
      !isSameUser && assignedUser && members.push(assignedUser);
      const responses = await assigneeTeamMember(lead?._id, members);
      const filteredResponse = responses.filter(Boolean);
      const updatedResponse = filteredResponse.length ? filteredResponse : members;
      updatedResponse.forEach(response => {
        const isNewUser = response?._id || response?.isNew;
        dispatch(!isNewUser ? removeDetailsViewDataAssignee(assignedUser) : addDetailsViewDataAssignee({
          ...newUser,
          _id: response?._id,
        }));
      });
      dispatch(setIsLoading(false));
      const analyst = userList?.find(assignee => assignee.designation === 'Analyst');
      const shouldLeadBeInProgress = designation === 'Analyst' ? !isSameUser : Boolean(analyst);
      const isLeadInProgress = lead?.status === 'In Progress';

      if (shouldLeadBeInProgress !== isLeadInProgress) {
        const leadStatus: StatusType = shouldLeadBeInProgress ? 'In Progress' : 'Waiting for Review';
        const updatedLead = {
          ...lead,
          status: leadStatus,
        };
        dispatch(updateLeadDetailViewData(updatedLead));
      }
    }
  }, [dispatch, lead, userList]);

  const steps = useMemo(() => genStepsByLeadDetails(detailsData), [detailsData]);

  const defaultOverviewComponent = useMemo(() => {
    return (
      <Overview
        showLearnMoreButton
        showCloud={showOverviewCloud}
        showSkeleton={!showData}
        showData={showData}
        scenario={scenario}
        learnMoreItems={learnMoreItems}
        landFields={landFields}
        summaryItems={customSummaryItemsOnOverview.length ? customSummaryItemsOnOverview : summaryItems}
        leadSummaryItems={leadSummaryItems}
        note={notes?.find(note => note.section === noteSection)?.note}
        steps={steps}
        {...cloudProps}
        {...overviewProps}
      />
    );
  }, [cloudProps, customSummaryItemsOnOverview, landFields, leadSummaryItems, learnMoreItems, noteSection, notes, overviewProps, scenario, showData, showOverviewCloud, summaryItems, steps]);

  const defaultReportComponent = useMemo(() => {
    return (
      <Reports/>
    );
  }, []);

  // Lead
  const leadOverview = useMemo(() => {
    return (
      <LeadOverview
        landFields={landFields}
        showCloud={!Boolean(userList?.length)}
        showSkeleton={!lead || isLoading}
        notes={lead?.note}
        summaryItems={learnMoreItems}
        coordinates={lead?.location?.coordinates}
        onAssignCloudBtnClick={cloudProps?.onBtnClickHandler}
        steps={steps}
      />
    );
  }, [userList?.length, cloudProps?.onBtnClickHandler, isLoading, landFields, lead, learnMoreItems, steps]);

  const leadReport = useMemo(() => {
    return (
      <Report {...leadReportProps}/>
    );
  }, [leadReportProps]);


  const marketplaceProjectOffering = useMemo(() => {
    return <MarketplaceProjectOffering
      isPartnerOffering={isPartnerOffering}
      chartData={offeringDetails?.fundingActivity}
      funding={offeringDetails?.fundingOverview}
      activityData={offeringDetails?.activities}
    />;
  }, [isPartnerOffering, offeringDetails?.activities, offeringDetails?.fundingActivity, offeringDetails?.fundingOverview]);

  const marketplaceProjectOverview = useMemo(() => {
    return <MarketplaceProjectOverview
      isPartner={isPartnerPortal}
      showProposalDetails
    />;
  }, [isPartnerPortal]);


  const defaultComponents = useMemo(() => {
    return {
      marketplaceProject: {
        Offering: marketplaceProjectOffering,
        Overview: marketplaceProjectOverview,
        Reports: defaultReportComponent,
      },
      lead: {
        Offering: <Offering
          noTableData={!currentProjectsOffer?.offer.marketplaces?.length}
          tableData={currentProjectsOffer?.offer.marketplaces}
        />,
        Overview: leadOverview,
        Reports: leadReport,
      },
      default: {
        Offering: <Offering
          noTableData={!currentProjectsOffer?.offer.marketplaces?.length}
          tableData={currentProjectsOffer?.offer.marketplaces}
        />,
        Overview: defaultOverviewComponent,
        Reports: defaultReportComponent,
      },
    };
  }, [marketplaceProjectOffering, marketplaceProjectOverview, currentProjectsOffer?.offer.marketplaces, leadOverview, leadReport, defaultOverviewComponent, defaultReportComponent]);

  const tabPanels: DetailViewTabPanelsType[] = useMemo(() => {
    return [
      {
        tabKey: 'overview',
        component: defaultComponents[componentsViews].Overview,
      },
      {
        tabKey: 'reports',
        component: defaultComponents[componentsViews].Reports,
      },
      {
        tabKey: 'team',
        component: <Team
          showAssignBtn={Boolean(lead)}
          showSkeleton={Boolean(isLoading)}
          handleAssignRole={handleAssign}
          projectManager={userList?.find(assignee => assignee.designation === 'Project manager')}
          analyst={userList?.find(assignee => assignee.designation === 'Analyst')}
        />,
      },
      {
        tabKey: 'files',
        component: <Files
          useCustomFiles={useCustomFiles}
          customFiles={customFiles}
        />,
      },
      {
        tabKey: 'assets',
        component: <Assets/>,
      },
      {
        tabKey: 'suppliers',
        component: <TenderingSuppliers/>,
      },
      {
        tabKey: 'financial',
        component: <Financial
          financialData={financialFromStore}
          project={detailsData?.project}
          financialSheet={financialSheet}
        />,
      },
      {
        tabKey: 'offering',
        component: defaultComponents[componentsViews].Offering,
      },
      {
        tabKey: 'tendering-request',
        component: <PreliminaryQuotationRequest/>,
      },
      {
        tabKey: 'uploading-financing-request',
        component: <UploadFinancingAgreementDocuments/>,
      },
      {
        tabKey: 'financing-debt-request',
        component: <FinancingDebtRequest crumbs={crumbs}/>,
      },
      {
        tabKey: 'sell-project',
        component: <SaleProject crumbs={crumbs}/>,
      },
      {
        tabKey: 'debt-financing-agreement',
        component: <SaleProject crumbs={crumbs}/>,
      },
      {
        tabKey: 'raise-monitoring',
        component: <RaiseMonitoring/>,
      },
      {
        tabKey: 'offering-publish',
        component: <OpportunityPublish/>,
      },
    ];
  }, [componentsViews, crumbs, customFiles, defaultComponents, detailsData?.project, financialFromStore, financialSheet, handleAssign, isLoading, lead, useCustomFiles, userList]);

  const handleTabOnChange = (_: SyntheticEvent, value: string) => {
    setCurrentTab(value);
    router.replace(value);
  };

  return (
    <Stack>
      {!formsWithHiddenTabs.includes(currentTab) &&
        <ProjectToolbar
          showSkeleton={!showData || isLoading}
          crumbs={crumbs}
          title={toolbarTitle}
          badgeTitle={toolbarBadgeTitle}
          showBadge={showToolbarBadge}
          badgeVariant={toolbarBadgeVariant}
          badgeColor={toolbarBadgeColor}
        >
          {toolbarChildren}
        </ProjectToolbar>
      }
      <TabContextCustom
        tabs={filteredTabs}
        defaultTabValue='overview'
        handleTabOnChange={handleTabOnChange}
        tabsSX={{
          ml: 3,
          pl: 0,
        }}
        tabPanels={tabPanels}
        currentTabValue={currentTab}
        hideTabs={formsWithHiddenTabs.includes(currentTab)}
      />
    </Stack>
  );
};
