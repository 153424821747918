import { demoScenario } from 'src/constants/demo-mock-data';

import { Finance } from '../../../../types';
import { TableHeadType } from '../leads-page';
import { TableFilters } from './types';

export const TABLE_HEAD: TableHeadType[] = [
  {
    _id: '',
    label: 'Name / Type',
    width: 140,
  },
  {
    _id: '',
    label: 'Total Funds',
    width: 120,
    align: 'right',
  },
  {
    _id: '',
    label: 'Debt / Equity',
    width: 110,
    align: 'right',
  },
  {
    _id: '',
    label: 'Structure',
    width: 120,
  },
  {
    _id: 'lastActivity',
    label: 'Last Activity',
    width: 130,
  },
  {
    _id: '',
    label: 'Stage',
    width: 50,
  },
  {
    _id: 'controls',
    width: 50,
  },
];

export const defaultFilters: TableFilters = {
  text: '',
  projectType: [],
  stage: 'All',
};

export const _financingItems: Finance[] = [
  {
    _id: '1',
    name: 'Igelsta Strandentré',
    type: 'New build apartments',
    totalFunds: 91_035_902,
    debt: 71.48,
    equity: 28.52,
    structure: ['Senior'],
    lastActivity: 'Added',
    lastActivityDate: new Date(new Date().setDate(new Date().getDate() - 5)).toISOString(),
    stage: 'Lead',
    scenario: demoScenario,
    lead: demoScenario.lead,
  },
  {
    _id: '2',
    name: 'Gökegårds Tun',
    type: 'New build apartments',
    totalFunds: 59_175_725,
    debt: 82.2,
    equity: 17.8,
    structure: ['Senior', 'Junior'],
    lastActivity: 'Docs Requested',
    lastActivityDate: new Date(new Date().setDate(new Date().getDate() - 5)).toISOString(),
    stage: 'In Progress',
    scenario: demoScenario,
    lead: demoScenario.lead,
  },
  {
    _id: '3',
    name: 'Igelsta Loft',
    type: 'New build houses',
    totalFunds: 267_051_125,
    debt: 78.3,
    equity: 12.7,
    structure: ['Senior'],
    lastActivity: '4/8 Docs Added',
    lastActivityDate: new Date(new Date().setDate(new Date().getDate() - 2)).toISOString(),
    stage: 'In Progress',
    scenario: demoScenario,
    lead: demoScenario.lead,
  },
  {
    _id: '4',
    name: 'Älvsjö Loft',
    type: 'New build houses',
    totalFunds: 145_298_187,
    debt: 85.5,
    equity: 15.5,
    structure: ['Senior'],
    lastActivity: '1 Offer Added',
    lastActivityDate: new Date(new Date().setDate(new Date().getDate() - 5)).toISOString(),
    stage: 'In Progress',
    scenario: demoScenario,
    lead: demoScenario.lead,
  },
  {
    _id: '5',
    name: 'Gunhild Ägarlägenheter',
    type: 'New build houses',
    totalFunds: 98_569_845,
    debt: 74.2,
    equity: 15.8,
    structure: ['Senior', 'Junior'],
    lastActivity: '2 Request Sent',
    lastActivityDate: new Date(new Date().setDate(new Date().getDate() - 5)).toISOString(),
    stage: 'Done',
    scenario: demoScenario,
    lead: demoScenario.lead,
  },
];
