import { useMemo } from 'react';
import { InvestorPage, PageWrap } from 'src/components';
import { ReportPage } from 'src/features/report-page';

import { usePortalFlags } from './use-portal-flags';

export const useLayoutByPath = () => {
  const { isInvestorPortal, isReportView, isFinUpraisalView } = usePortalFlags();

  const Layout = useMemo(() => {
    if (isInvestorPortal) return InvestorPage;
    if (isReportView || isFinUpraisalView) return ReportPage;
    return PageWrap;
  }, [isInvestorPortal, isReportView, isFinUpraisalView]);

  return Layout;
};
