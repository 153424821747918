import { usePathname } from './use-pathname';

export const usePortalFlags = () => {
  const pathname = usePathname();

  const isFinUpraisalView = pathname.startsWith('/report/fin-upraisal/');
  const isCalculatorReport = pathname.startsWith('/report/calculator/');
  const isReportView = pathname.startsWith('/report/');
  const isInvestorPortal = pathname.startsWith('/investor');
  const isPartnerPortal = pathname.startsWith('/partner-portal');

  return {
    isInvestorPortal,
    isPartnerPortal,
    isReportView,
    isFinUpraisalView,
    isCalculatorReport,
  };
};
