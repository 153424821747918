import { ReadyForInvestorParams } from 'src/features/ready-for-investors-page';

import { ReadyForInvestorsListResponse } from '../../../../types';
import { apiFetch } from '../api';
import { setIsLoading } from '../storage';
import { AppThunk } from '../store';
import { deleteReadyForInvestorItem, setCurrentReadyForInvestorsItem, setReadyForInvestors } from '.';
import { CreateInvestorRequestType } from './types';

export const loadReadyForInvestorList = (paramBody: ReadyForInvestorParams): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(setIsLoading(true));
    const readyForInvestorsListResponse: ReadyForInvestorsListResponse = await apiFetch('ready-for-investors/list', {
      method: 'post',
      body: JSON.stringify(paramBody),
      headers: { 'Content-Type': 'application/json' },
    });
    if (readyForInvestorsListResponse !== undefined) {
      dispatch(setReadyForInvestors(readyForInvestorsListResponse));
    }
    dispatch(setIsLoading(false));
  } catch (error) {
    console.error(error);
    dispatch(setIsLoading(false));
  }
};

export const createInvestorRequest = (paramBody: CreateInvestorRequestType): AppThunk => async (dispatch, getState) => {
  const isDemo = getState().App.config?.isDemo;
  if (isDemo) {
    return;
  }

  try {
    dispatch(setIsLoading(true));
    await apiFetch('ready-for-investors/investor-request', {
      method: 'post',
      body: JSON.stringify(paramBody),
      headers: { 'Content-Type': 'application/json' },
    });
    dispatch(setIsLoading(false));
  } catch (error) {
    console.error(error);
    dispatch(setIsLoading(false));
  }
};


export const deleteReadyForInvestorThunk = (id: string): AppThunk => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    dispatch(deleteReadyForInvestorItem(id));
    dispatch(setIsLoading(false));
  } catch (error) {
    console.error(error);
    dispatch(setIsLoading(false));
  }
};

export const loadReadyForInvestorsDetails = (itemId: string): AppThunk => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    // TODO change logic
    dispatch(setCurrentReadyForInvestorsItem(itemId));
    dispatch(setIsLoading(false));
  } catch (error) {
    console.error(error);
    dispatch(setIsLoading(false));
  }
};
