import Stack from '@mui/material/Stack';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UploadedFilesType } from 'src/components';
import { RootState } from 'src/store';
import { createDocument, uploadDocument } from 'src/store/financial';
import { deleteQuotation, getQuotationUploadedFiles, updateQuotation } from 'src/store/tendering';

import { DocumentListResponse, QuotationType } from '../../../../../../types';
import { QuotationRequests } from './components';
import { Quotations } from './components/quotations/quotations';
import { RequestDrawerSubmit } from './components/quotations/request-drawer';
import { UploadFormDrawerSubmit } from './components/quotations/upload-form-drawer';

export const TenderingSuppliers = () => {
  const detailData = useSelector((state: RootState) => state.Storage.detailsData);
  const isDemo = useSelector((state: RootState) => state.App.config?.isDemo);
  const dispatch = useDispatch();

  const [quotations, setQuotations] = useState<QuotationType[]>([]);
  const [quotationRequests, setQuotationRequests] = useState<QuotationType[]>([]);

  const loadQuotationLists = useCallback(() => {
    if (detailData.project?._id) {
      setQuotations(detailData.tendering?.filter(item => item.preliminaryOffer?.constructionCost) || []);
      setQuotationRequests(detailData.tendering?.filter(item => !item.preliminaryOffer?.constructionCost) || []);
    }
  }, [detailData.project?._id, detailData.tendering]);

  useEffect(() => {
    loadQuotationLists();
  }, [loadQuotationLists]);

  const deleteOnClickHandler = (setter: Dispatch<SetStateAction<QuotationType[]>>) => (quotationId: string) => {
    dispatch(deleteQuotation(quotationId));
    setter(prevState => prevState.filter(item => item._id !== quotationId));
  };

  const uploadDocumentToQuotation = async (quotationId: string = '', sectionId: string = '', data: UploadFormDrawerSubmit) => {
    let fileId = '';
    if (data.file) {
      const documentId = await createDocument({
        quotationId: quotationId,
        sectionIds: [sectionId],
      }, isDemo);
      fileId = documentId?.[0] || '';
      await uploadDocument({
        ...data.file,
        _id: documentId?.[0] || '',
      }, isDemo);
    }
    return {
      constructionCost: data.costs,
      constructionPsqm: data.psqm,
      recalculateNote: data.recalculateNote,
      isCalculate: data.isCalculate,
      buildTime: data.buildTimeMonths,
      partnerFee: data.feePercent,
      documentId: fileId ? [fileId] : [],
    };
  };

  const addOfferSubmitFunction = (item: QuotationType) => async (data: UploadFormDrawerSubmit) => {
    const updatedData = await uploadDocumentToQuotation(item._id, item.preliminaryOffer?.sectionId, data);
    dispatch(updateQuotation(item._id, { preliminaryOffer: updatedData }));
    setQuotations([...quotations, {
      ...item,
      preliminaryOffer: updatedData,
      stage: 'Preliminary',
    }]);
    setQuotationRequests(prev => prev.filter(prevItem => prevItem._id !== item._id));
  };

  const sendReqItemizedHandler = (item: QuotationType) => (data: RequestDrawerSubmit) => {
    dispatch(updateQuotation(item._id, { itemisedDetail: { request: { ...data } } }));
    setQuotations(prevState => prevState.map(quotation => quotation._id === item._id ? {
      ...quotation,
      stage: 'Itemized requested',
      itemisedDetail: {
        ...item.itemisedDetail,
        request: { ...data },
      },
    } : quotation));
  };

  const sendItemizedHandler = (item: QuotationType) => async (data: UploadFormDrawerSubmit) => {
    const updatedData = await uploadDocumentToQuotation(item._id, item.itemisedDetail?.sectionId, data);
    dispatch(updateQuotation(item._id, {
      itemisedDetail: {
        ...item.itemisedDetail,
        response: { ...updatedData },
      },
    }));
    setQuotations(prevState => prevState.map(quotation => quotation._id === item._id ? {
      ...quotation,
      stage: 'Itemized',
      itemisedDetail: {
        ...item.itemisedDetail,
        response: { ...updatedData },
      },
    } : quotation));
  };

  const sendReqDetailedHandler = (item: QuotationType) => (data: RequestDrawerSubmit) => {
    dispatch(updateQuotation(item._id, { detailedQuotation: { request: { ...data } } }));
    setQuotations(prevState => prevState.map(quotation => quotation._id === item._id ? {
      ...quotation,
      stage: 'Detailed requested',
      detailedQuotation: {
        ...item.detailedQuotation,
        request: { ...data },
      },
    } : quotation));
  };

  const sendDetailedHandler = (item: QuotationType) => async (data: UploadFormDrawerSubmit) => {
    const updatedData = await uploadDocumentToQuotation(item._id, item.detailedQuotation?.sectionId, data);
    dispatch(updateQuotation(item._id, {
      detailedQuotation: {
        ...item.detailedQuotation,
        response: { ...updatedData },
      },
    }));
    setQuotations(prevState => prevState.map(quotation => quotation._id === item._id ? {
      ...quotation,
      stage: 'Detailed',
      detailedQuotation: {
        ...item.detailedQuotation,
        response: { ...updatedData },
      },
    } : quotation));
  };

  const sendReqTermSheetHandler = (item: QuotationType) => (data: RequestDrawerSubmit) => {
    dispatch(updateQuotation(item._id, { termSheet: { request: { ...data } } }));
    setQuotations(prevState => prevState.map(quotation => quotation._id === item._id ? {
      ...quotation,
      stage: 'Detailed',
      status: 'Term sheet requested',
      termSheet: {
        ...item.termSheet,
        request: { ...data },
      },
    } : quotation));
  };

  const sendUploadTermSheetHandler = (item: QuotationType) => async (data: UploadedFilesType) => {
    const updatedData = await uploadDocumentToQuotation(item._id, item.termSheet?.sectionId, {
      file: data,
      psqm: 0,
      costs: 0,
      feePercent: 0,
      buildTimeMonths: 0,
    });
    dispatch(updateQuotation(item._id, {
      termSheet: {
        ...item.termSheet,
        response: { ...updatedData },
      },
    }));
    setQuotations(prevState => prevState.map(quotation => quotation._id === item._id ? {
      ...quotation,
      stage: 'Detailed',
      status: 'Term Sheet',
      termSheet: {
        ...item.termSheet,
        response: { documentId: updatedData.documentId },
      },
    } : quotation));
  };

  const getUploadedFiles = async (item: QuotationType): Promise<DocumentListResponse[]> => {
    const files = await getQuotationUploadedFiles(item._id, isDemo);
    return files || [];
  };

  return (
    <Stack gap={5}>
      {/*<SuppliersProgress/>*/}
      {quotations.length > 0 &&
        <Quotations
          deleteOnClickHandler={deleteOnClickHandler(setQuotations)}
          quotations={quotations}
          sendReqItemizedHandler={sendReqItemizedHandler}
          sendItemizedHandler={sendItemizedHandler}
          sendReqDetailedHandler={sendReqDetailedHandler}
          sendDetailedHandler={sendDetailedHandler}
          sendReqTermSheetHandler={sendReqTermSheetHandler}
          sendUploadTermSheetHandler={sendUploadTermSheetHandler}
          getUploadedFiles={getUploadedFiles}
        />
      }
      <QuotationRequests
        deleteOnClickHandler={deleteOnClickHandler(setQuotationRequests)}
        addOfferSubmitFunction={addOfferSubmitFunction}
        requests={quotationRequests}
      />
    </Stack>
  );
};
